<template>
    <div class="header">
        <div class="header-container">
            <div class="logo">
                <img src="@/assets/af_logo.png" alt="Logo">
            </div>
            <div v-if="!isMobile">
                <Menu v-model:selectedKeys="currentRoute" mode="horizontal"
                    :style="{ fontSize: '20px', fontWeight: 'bold' }">
                    <MenuItem key="home" @click="routeTo('home')">{{ $t('home') }}</MenuItem>
                    <MenuItem key="company" @click="routeTo('company')">{{ $t('companyProfile') }}</MenuItem>
                    <MenuItem key="terms" @click="routeTo('terms')">{{ $t('termNCondition') }}</MenuItem>
                    <MenuItem key="policy" @click="routeTo('policy')">{{ $t('privacyPolicy') }}</MenuItem>
                    <!-- <MenuItem key="contact" @click="routeTo('contact')">{{ $t('contactUs') }}</MenuItem> -->
                </Menu>
            </div>
            <MenuOutlined v-else type="menu" class="menu" @click="showMenu = true" />
            <Drawer title="AimsFun" :open="showMenu" :width="pageWidth" :closable="false">
                <template #extra>
                    <CloseOutlined :style="{ fontSize: '24px' }" @click="showMenu = false" />
                </template>
                <p class="menu-item">
                    <a href="javascript:void(0)" @click="showMenu = false & router.push('/')">
                        {{ $t('home') }}
                    </a>
                </p>
                <p class="menu-item">
                    <a href="javascript:void(0)" @click="showMenu = false & router.push('/company')">
                        {{ $t('companyProfile') }}
                    </a>
                </p>
                <p class="menu-item">
                    <a href="javascript:void(0)" @click="showMenu = false & router.push('/terms')">
                        {{ $t('termNCondition') }}
                    </a>
                </p>
                <p class="menu-item">
                    <a href="javascript:void(0)" @click="showMenu = false & router.push('/policy')">
                        {{ $t('privacyPolicy') }}
                    </a>
                </p>
                <!-- <p class="menu-item">
                    <a class="drawer-item" href="javascript:void(0)" @click="router.push('/contact')">
                        {{ $t('contactUs') }}
                    </a>
                </p> -->
            </Drawer>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons-vue';
import { Drawer, Menu, MenuItem } from 'ant-design-vue';
import { useRouter, useRoute } from 'vue-router';
import { watch } from 'vue';

const route = useRoute();
const router = useRouter();
const currentRoute = ref([route.name]);
const isMobile = ref(window.innerWidth < 650);
const showMenu = ref(false);
const pageWidth = ref(window.innerWidth);
const routeTo = (key) => {
    router.push({ name: key });
}
watch(() => route.name, (newName) => {
    currentRoute.value = [newName];
});
</script>

<style lang="less" scoped>
@import '@/style/header.less';
</style>
