<template>
  <div class="container">
    <div class="currency-group" v-for="(currency, index) in viewData" :key="index">
      <!-- <h1>{{ $t(currency.currency) }}</h1> -->
      <div class="quotes">
        <div class="card" v-for="(item, i) in currency.data" :key="i">
          <div class="currency-header">
            <img :src="item.qLogo" class="currency-icon" />
            <span class="instrument">{{ item.quote }}</span>
            <span class="instrument">/</span>&nbsp;&nbsp;&nbsp;
            <img :src="item.bLogo" class="currency-icon" />
            <span class="instrument">{{ item.base }}</span>
          </div>
          <div class="price-info">
            <div class="price">
              <span class="price-label">{{ $t('buyPrice') }}</span>
              <span class="price-amount">{{ item.buyPrice }}</span>
            </div>
            <div class="price">
              <span class="price-label">{{ $t('sellPrice') }}</span>
              <span class="price-amount">{{ item.sellPrice }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { fetchPrice } from '@/api/api';

const viewData = ref([
  {
    currency: 'aud',
    data: [
      {
        quote: 'USD',
        base: 'AUD',
        qLogo: require('../assets/currency/USD.png'),
        bLogo: require('../assets/currency/AUD.png'),
        buyPrice: '1.5469',
        sellPrice: '1.5869'
      },
      {
        quote: 'CNY',
        base: 'AUD',
        qLogo: require('../assets/currency/CNY.png'),
        bLogo: require('../assets/currency/AUD.png'),
        buyPrice: '4.6664',
        sellPrice: '4.8336'
      },
      {
        quote: 'JPY',
        base: 'AUD',
        qLogo: require('../assets/currency/JPY.png'),
        bLogo: require('../assets/currency/AUD.png'),
        buyPrice: '98.0631',
        sellPrice: '106.1895'
      },
      {
        quote: 'SGD',
        base: 'AUD',
        qLogo: require('../assets/currency/SGD.png'),
        bLogo: require('../assets/currency/AUD.png'),
        buyPrice: '0.8343',
        sellPrice: '0.9394'
      }
    ]
  }
]);

let timer = null;

const formatData = (data) => {
  const currencyIcons = {
    USD: require('../assets/currency/USD.png'),
    AUD: require('../assets/currency/AUD.png'),
    CNY: require('../assets/currency/CNY.png'),
    JPY: require('../assets/currency/JPY.png'),
    SGD: require('../assets/currency/SGD.png'),
    GBP: require('../assets/currency/GBP.png'),
    NZD: require('../assets/currency/NZD.png'),
    CAD: require('../assets/currency/CAD.png'),
    EUR: require('../assets/currency/EUR.png'),
    TWD: require('../assets/currency/TWD.png'),
    KRW: require('../assets/currency/KRW.png'),
    HKD: require('../assets/currency/HKD.png'),
    INR: require('../assets/currency/INR.png'),
    MYR: require('../assets/currency/MYR.png'),
    THB: require('../assets/currency/THB.png')
  };

  return [
    {
      data: data.map((item) => ({
        quote: item.quote,
        base: item.base,
        qLogo: currencyIcons[item.quote] || '',
        bLogo: currencyIcons[item.base] || '',
        buyPrice: item.buyPrice,
        sellPrice: item.sellPrice,
      })).filter((item) => item.qLogo),
    },
  ];
};

const loadPrices = async () => {
  try {
    const response = await fetchPrice();
    if (response.code === 'G_0000' && response.data) {
      viewData.value = formatData(response.data);
    }
  } catch (error) {
    console.error('Failed to fetch price:', error);
  }
};

onMounted(() => {
  loadPrices();

  timer = setInterval(loadPrices, 15000);
});

onBeforeUnmount(() => {
  if (timer) {
    clearInterval(timer);
  }
});
</script>

<style lang="less" scoped>
@import '@/style/price_board.less';
</style>
