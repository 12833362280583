import App from '@/App.vue'
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n'
import router from './router';
import '@/style/main.less';
import en from '@/locales/en.js';
import zhHans from '@/locales/zh-Hans.js';
import zhHant from '@/locales/zh-Hant.js';

const app = createApp(App);

const messages = {
  'en': en,
  'zh-Hans': zhHans,
  'zh-Hant': zhHant
};

const i18n = createI18n({
  locale: localStorage.getItem('locale') || 'en',
  messages,
});

app.config.productionTip = false

app.use(i18n);
app.use(router);
app.mount('#app');