<template>
    <div class="company-container">
        <div class="outer-container1">
            <section class="container">
                <img src="@/assets/ic_experience.svg" class="company-image" />
                <div class="company-text">
                    <div class="company-title">{{ $t('company.experienceTitle') }}</div>
                    <div class="company-text">{{ $t('company.experience') }}</div>
                </div>
            </section>
        </div>
        <div class="outer-container2">
            <section class="container">
                <div class="company-text" v-if="!isMobile">
                    <div class="company-title">{{ $t('company.safeTitle') }}</div>
                    <div class="company-text">{{ $t('company.safe') }}</div>
                </div>
                <img src="@/assets/ic_safe.svg" class="company-image" />
                <div class="company-text" v-if="isMobile">
                    <div class="company-title">{{ $t('company.safeTitle') }}</div>
                    <div class="company-text">{{ $t('company.safe') }}</div>
                </div>
            </section>
        </div>
        <div class="outer-container1">
            <section class="container">
                <img src="@/assets/ic_fast.svg" class="company-image" />
                <div class="company-text">
                    <div class="company-title">{{ $t('company.fastTitle') }}</div>
                    <div class="company-text">{{ $t('company.fast') }}</div>
                </div>
            </section>
        </div>
        <div class="outer-container2">
            <section class="container">
                <div class="company-text" v-if="!isMobile">
                    <div class="company-title">{{ $t('company.currencyTitle') }}</div>
                    <div class="company-text">{{ $t('company.currency') }}</div>
                </div>
                <img src="@/assets/ic_currency.svg" class="company-image" />
                <div class="company-text" v-if="isMobile">
                    <div class="company-title">{{ $t('company.currencyTitle') }}</div>
                    <div class="company-text">{{ $t('company.currency') }}</div>
                </div>
            </section>
        </div>
        <div class="outer-container1">
            <section class="container">
                <img src="@/assets/ic_contact.svg" class="company-image" />
                <div class="company-text">
                    <div class="company-title">{{ $t('company.contactTitle') }}</div>
                    <div class="company-text">{{ $t('company.contactUs') }}</div>
                </div>
            </section>
        </div>
    </div>
</template>
<script setup>
import { ref } from 'vue';

const isMobile = ref(window.innerWidth < 650);


</script>
<style lang="less" scoped>
@import '@/style/company.less';
</style>