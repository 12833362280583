import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/page/HomePage.vue';
import LoginPage from '@/page/LoginPage.vue';
import ProfilePage from '@/page/ProfilePage.vue';
import CompanyPage from '@/page/CompanyPage.vue';
import PolicyPage from '@/page/PolicyPage.vue';
import TermsPage from '@/page/TermsPage.vue';
import ContactUsPage from '@/page/ContactUsPage.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', name: 'home', component: HomePage },
        { path: '/login', name: 'login', component: LoginPage },
        { path: '/profile', name: 'profile', component: ProfilePage, meta: { requireAuth: true } },
        { path: '/company', name: 'company', component: CompanyPage },
        { path: '/policy', name: 'policy', component: PolicyPage },
        { path: '/terms', name: 'terms', component: TermsPage },
        { path: '/contact', name: 'contact', component: ContactUsPage },
    ]
})

// router.beforeEach((to, from, next) => {
//     const user = localStorage.getItem('user');
//     if (to.path === '/login' && user) {
//         next({ path: '/profile' });
//     } else if (to.matched.some(record => record.meta.requiresAuth)) {
//         if (user) {
//             next()
//         } else {
//             next({
//                 path: '/login',
//                 query: { redirect: to.fullPath }
//             })
//         }
//     } else {
//         next()
//     }
// })


export default router
