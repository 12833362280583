import axios from 'axios';
import { logger } from '@/utils/logger';

axios.defaults.withCredentials = true;

const service = axios.create({
  // baseURL: config.baseUrl,
  baseURL: "https://www.aimsfun.com.au",
  timeout: 10000,
  withCredentials: true
});

service.interceptors.request.use(
  (config) => {
    const user = localStorage.getItem('user');
    if (user && user.token) {
      config.headers['Authorization'] = user.token;
    }
    return config;
  },
  (error) => {
    logger.error('Request error: ', error);
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    const res = response.data;
    // if (res.code === 401) {
    //   localStorage.removeItem('token');
    //   this.$router.replace('/login');
    // } else if (res.code !== 200) {
    //   logger.error('Error: ', res.msg || 'Error');
    //   return Promise.reject(new Error(res.msg || 'Error'));
    // } else {
      return res;
    // }
  },
  (error) => {
    logger.error('Response error: ', error.response?.data?.message || error.message);
    return Promise.reject(error);
  }
);

export default service;
