import service from '@/api/request';
import config from '@/config/config';

export function captchaUrl(id) {
    const timestamp = new Date().getTime();
    return config.baseUrl + '/auth/captcha?id=' + id + '&t=' + timestamp;
}

export function sendEmail(data) {
    return service.post('/auth/send-email', data);
}

export function verifyEmail(data) {
    return service.post('/auth/verify-email', data);
}

export function fetchPrice() {
    return service.get('/api/calculator/info');
}