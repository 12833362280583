<template>
    <div class="form-container">
        <img src="@/assets/ic_login_logo.svg" class="login-logo" />
        <div class="input-container">
            <Input v-model:value="email" :placeholder="$t('email')">
            <template #prefix>
                <MailOutlined />
            </template>
            </Input>
        </div>
        <div class="input-container">
            <Input v-model:value="verificationCode" :placeholder="$t('verificationCode')">
            <template #prefix>
                <FieldStringOutlined />
            </template>
            </Input>
            <div style="display: block; width: 16px;" />
            <Button @click="getCaptcha" type="primary" class="common-button">
                {{ $t('verificationCode') }}
            </Button>
        </div>
        <Modal :open="captchaShow" :confirm-loading="confirmLoading" @cancel="handleCancel">
            <div class="pop-container">
                <div class="input-container">
                    <Input v-model:value="captcha" placeholder="Captcha" :maxLength="4">
                    <template #prefix>
                        <FieldStringOutlined />
                    </template>
                    </Input>
                    <div style="display: block; width: 16px;" />
                    <img v-if="captchaShow" :src="captchaImageUrl" class="captcha" @click="getCaptcha" />
                </div>
            </div>
            <template v-slot:footer>
                <Button key="back" @click="handleCancel">
                    {{ $t('cancel') }}
                </Button>
                <Button key="submit" type="primary" :loading="sendEmailLoading" @click="sendEmailAction">
                    {{ $t('sendVerificationEmail') }}
                </Button>
            </template>
        </Modal>
        <Button type="primary" @click="verifyEmailAction" class="submit-button" :loading="verifyEmailLoading">
            {{ $t('verifyEmail') }}
        </Button>
    </div>
</template>

<script setup>
import { Button, Input, Modal, message } from 'ant-design-vue';
import { captchaUrl, sendEmail, verifyEmail } from '@/api/api';
import { MailOutlined, FieldStringOutlined } from '@ant-design/icons-vue';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const email = ref('');
const captcha = ref('');
const captchaImageUrl = ref('');
const verificationCode = ref('');
const captchaShow = ref(false);
const sendEmailLoading = ref(false);
const verifyEmailLoading = ref(false);
const confirmLoading = ref(false);
const router = useRouter();

const getCaptcha = () => {
    if (!validateInput()) {
        message.error('Please enter a valid email');
        return;
    }
    captchaShow.value = true;
    captchaImageUrl.value = captchaUrl(email.value);
}

const validateInput = () => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email.value);
}

const sendEmailAction = async () => {
    try {
        if (!captcha.value) {
            message.error('Please enter the captcha');
            return;
        }
        if (!validateInput()) {
            captchaImageUrl.value = ''
            captchaShow.value = false
            message.error('Please enter a valid email');
            return;
        }
        sendEmailLoading.value = true;
        const data = await sendEmail({
            email: email.value,
            captcha: captcha.value,
        });

        message.success(data.msg);
    } catch (error) {
        message.error(error.message);
    }
    captchaShow.value = false;
    sendEmailLoading.value = false;
}

const verifyEmailAction = async () => {
    try {
        if (!verificationCode.value) {
            message.error('Please enter the verification code');
            return;
        }
        if (!validateInput()) {
            message.error('Please enter a valid email');
            return;
        }
        verifyEmailLoading.value = true;
        const data = await verifyEmail({
            email: email.value,
            code: verificationCode.value,
        });
        message.success(data.msg);
        localStorage.setItem('user', JSON.stringify(data.data));
        router.push({ path: '/profile' });
    } catch (error) {
        message.error(error.message);
    }
    verifyEmailLoading.value = false;
}

const handleCancel = () => {
    captchaShow.value = false
}
</script>

<style lang="less" scoped>
@import '@/style/login.less';
</style>