<template>
    <div class="terms-container">
        <div class="terms-title">
            <h1>{{ $t('termNCondition') }}</h1>
        </div>
        <div class="terms-content">
            <h2>{{ $t('terms.introduction') }}</h2>
            <p v-html="$t('terms.policyIntroduction')" />

            <h2>{{ $t('terms.amendments') }}</h2>
            <p v-html="$t('terms.policyAmendments')" />

            <h2>{{ $t('terms.platformDescription') }}</h2>
            <p v-html="$t('terms.policyPlatformDescription')" />

            <h2>{{ $t('terms.currencyRisk') }}</h2>
            <p v-html="$t('terms.policyCurrencyRisk')" />

            <h2>{{ $t('terms.audTransfers') }}</h2>
            <p v-html="$t('terms.policyAUDTransfers')" />

            <h2>{{ $t('terms.transferMethods') }}</h2>
            <p v-html="$t('terms.policyTransferMethods')" />

            <h2>{{ $t('terms.identityVerification') }}</h2>
            <p v-html="$t('terms.policyIdentityVerification')" />

            <h2>{{ $t('terms.restrictions') }}</h2>
            <p v-html="$t('terms.policyRestrictions')" />

            <h2>{{ $t('terms.liability') }}</h2>
            <p v-html="$t('terms.policyLiability')" />

            <h2>{{ $t('terms.platformAccess') }}</h2>
            <p v-html="$t('terms.policyPlatformAccess')" />

            <h2>{{ $t('terms.security') }}</h2>
            <p v-html="$t('terms.policySecurity')" />

            <h2>{{ $t('terms.intellectualProperty') }}</h2>
            <p v-html="$t('terms.policyIntellectualProperty')" />

            <h2>{{ $t('terms.rightsReserved') }}</h2>
            <p v-html="$t('terms.policyRightsReserved')" />

            <h2>{{ $t('terms.taxes') }}</h2>
            <p v-html="$t('terms.policyTaxes')" />

            <h2>{{ $t('terms.termination') }}</h2>
            <p v-html="$t('terms.policyTermination')" />

            <h2>{{ $t('terms.noWaiver') }}</h2>
            <p v-html="$t('terms.policyNoWaiver')" />

            <h2>{{ $t('terms.assignment') }}</h2>
            <p v-html="$t('terms.policyAssignment')" />

            <h2>{{ $t('terms.severability') }}</h2>
            <p v-html="$t('terms.policySeverability')" />

            <h2>{{ $t('terms.entireAgreement') }}</h2>
            <p v-html="$t('terms.policyEntireAgreement')" />

            <h2>{{ $t('terms.jurisdiction') }}</h2>
            <p v-html="$t('terms.policyJurisdiction')" />
        </div>
    </div>
</template>

<style lang="less" scoped>
@import '@/style/terms.less';
</style>