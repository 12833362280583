<template>
    <div class="policy-container">
        <div class="policy-title">
            <h1>{{ $t('privacyPolicy') }}</h1>
        </div>
        <div class="policy-content">
            <p v-html="$t('policy.aimsfunPty')" />
            <p v-html="$t('policy.policyOverview')" />
            <p v-html="$t('policy.notice')" />
            <h2>{{ $t('policy.personalInformationTitle') }}</h2>
            <p v-html="$t('policy.personalInformationList')" />

            <h2>{{ $t('policy.usePersonalInformationTitle') }}</h2>
            <p v-html="$t('policy.usePersonalInformationList')" />

            <h2>{{ $t('policy.disclosureTitle') }}</h2>
            <p v-html="$t('policy.disclosureList')" />

            <h2>{{ $t('policy.linksTitle') }}</h2>
            <p v-html="$t('policy.linksContent')" />

            <h2>{{ $t('policy.storageTitle') }}</h2>
            <p v-html="$t('policy.storageContent')" />

            <h2>{{ $t('policy.accessingTitle') }}</h2>
            <p v-html="$t('policy.accessingContent')" />

            <h2>{{ $t('policy.dataTitle') }}</h2>
            <p v-html="$t('policy.dataContent')" />

            <h2>{{ $t('policy.privacyTitle') }}</h2>
            <p v-html="$t('policy.privacyContent')" />

            <h2>{{ $t('policy.contactTitle') }}</h2>
            <p v-html="$t('policy.contactContent')" />
        </div>
    </div>
</template>
<style lang="less" scoped>
@import '@/style/policy.less';
</style>
