<template>
    <div class="footer">
        <div class="footer-container">
            <div class="logo-container">
                <img src="@/assets/af_logo.png" class="logo">
                <div class="copyright" v-html="$t('copyright')" />
            </div>
            <div class="links">
                <p>
                    <a href="javascript:void(0)" @click="routeTo('home')">{{ $t('home') }}</a>
                    <a href="javascript:void(0)" @click="routeTo('company')">{{ $t('companyProfile') }}</a>
                </p>
                <p>
                    <a href="javascript:void(0)" @click="routeTo('policy')">{{ $t('privacyPolicy') }}</a>
                    <a href="javascript:void(0)" @click="routeTo('terms')">{{ $t('termNCondition') }}</a>
                </p>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useRouter } from 'vue-router';

const router = useRouter();
const routeTo = (key) => {
    router.push({ name: key });
}
</script>
<style lang="less" scoped>
@import '@/style/footer.less';
</style>