<template>
    <div class="switcher">
        <FloatButtonGroup>
            <FloatButton shape="square" type="primary" :description="language" @click="switchLanguage" />
            <BackTop shape="square" type="primary" :visibility-height="800" />
        </FloatButtonGroup>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { FloatButtonGroup, FloatButton, BackTop } from 'ant-design-vue';

const { locale } = useI18n();
const language = ref('EN');
const localeMap = [
    { v: 'zh-Hans', label: '简', next: 'zh-Hant', nextLabel: '繁' },
    { v: 'zh-Hant', label: '繁', next: 'en', nextLabel: 'EN' },
    { v: 'en', label: 'EN', next: 'zh-Hans', nextLabel: '简' },
]

onMounted(() => {
    getLocale();
});

const getLocale = () => {
    const l = localStorage.getItem('locale');
    localeMap.forEach(e => {
        e.v === l && (language.value = e.label);
    });
};

const switchLanguage = () => {
    for (const e of localeMap) {
        if (e.label === language.value) {
            language.value = e.nextLabel
            localStorage.setItem('locale', e.next);
            locale.value = e.next;
            window.location.reload();
            break;
        }
    }
};
</script>

<style lang="less" scoped>
@import '@/style/switcher.less';
</style>